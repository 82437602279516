.main-header-nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 0.2fr;
  margin-bottom: 4%;
}

.main-header-nav h1 {
  grid-column: 1/4;
  grid-row: 1/2;
  display: block;
  margin: 0;
}

.main-header-nav h1 img {
  width: 100%;
}

.main-header-nav ul {
  grid-column: 1/4;
  grid-row: 2/3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-header-nav ul li {
  display: inline-block;
  width: 33%;
  text-align: center;
  font-size: 0.8em;
  font-weight: bold;
}

.main-header-nav ul li a {
  text-decoration: none;
  color: rgb(44, 46, 54);
}

@media only screen and (min-width: 760px) {
  .main-header-nav h1 {
    grid-column: 2/3;
    grid-row: 1/2;
    display: block;
  }

  .main-header-nav ul {
    margin: 0 12%;
  }
}