.editor-container {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(36, 36, 36, 0.685);
  top: 0;
  z-index: 300;
}

.editor-background {
  z-index: 400;
  position: fixed;
  width: 100%;
  height: 100vh;
}

.editor-field {
  background-color: rgb(36, 36, 36);
  color: #f8f9fe;
  margin-top: 10%;

  -webkit-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  -moz-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);

  z-index: 500;
}

.delete-field {
  min-height: 0;
  padding-bottom: 6%;
  margin-top: 10%;
}

.editor-button {
  background-color: rgb(207, 236, 250);
  color: rgb(36, 36, 36);
  -webkit-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  -moz-box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
  box-shadow: 0px 10px 34px -16px rgb(34, 34, 36);
}

.cancel-button {
  background-color: rgb(228, 17, 27);
  color: #f8f9fe;
}