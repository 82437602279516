.auth-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 200;
}

.auth-footer button {
  border: none;
  height: 8vh;
  color: #f8f9fe;
  font-size: 0.8em;
}

.footer-add-image {
  background-color: rgb(116, 116, 116);
  width: 60%;
}

.footer-logout {
  background-color: rgb(255, 0, 0);
  width: 40%;
}

@media only screen and (min-width: 760px) {
  .auth-footer button {
    height: 4vh;
    cursor: pointer;
  }
}