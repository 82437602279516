.generated-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-bottom: 8vh;
}

.img-container {
  position: relative;
}

.img-container img {
  width: 100%;
  max-width: 420px;
  display: block;
  margin-bottom: 4%;
}

@media only screen and (min-width: 760px) {
  .generated-images {
    flex-flow: wrap;
    align-items: flex-start;
    max-width: 850px;
    margin: auto;
    margin-bottom: 4vh;
  }

  .img-container {
    display: grid;
    grid-template-rows: 0.5fr 0.5fr;
    position: relative;
    max-width: 300px;
    margin: 1.5%;
  }

  .img-container img {
    grid-row: 1/3;
    max-width: 238px;
    margin-bottom: 0;
  }
}